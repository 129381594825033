import { __makeGetDashboardGetRequest } from "constants/apis"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, CardText, Col } from "reactstrap"

const todaydate = new Date()
const Summery = () => {
  const [isLoading, setisLoading] = useState(false)
  const [dashboard, setdashboard] = useState({
    total: 0,
    M: 0,
    F: 0,
    new_voters: 0,
    age_18_to_35: 0,
    age_36_to_150: 0,
    dob: 0,
    mobile: 0,
    ps_building: 0,
    tehsil_nm_v: 0,
    cst: 0,
    wardno: 0,
  })

  const __handleGetDashboardGetRequest = () => {
    setisLoading(true)
    __makeGetDashboardGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setdashboard(res?.data)
        }
        setisLoading(false)
      })
      .catch(error => {
        console.error(error)
        setisLoading(false)
      })
  }

  useEffect(() => {
    __handleGetDashboardGetRequest()
  }, [])

  return (
    <>
      <Col md={2}>
        <Link to="/voter-list">
          <Card className="bg-primary shadow-sm  bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.total || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Total Voters</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/first-time-voting">
          <Card className="bg-success shadow-sm  bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.new_voters || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Total New Voters</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/voter-list">
          <Card className="bg-info shadow-sm   bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.mobile || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Mobile Updated</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/voter-list?s=M">
          <Card className="bg-warning shadow-sm  bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">{dashboard?.M || 0}</span>
              )}
              <CardText className="mt-3 text-white">Total Male Voters</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/voter-list?s=F">
          <Card className="bg-danger  shadow-sm bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">{dashboard?.F || 0}</span>
              )}
              <CardText className="mt-3 text-white">
                Total Female Voters
              </CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/voter-list?max=35&min=18">
          <Card className="bg-dark  shadow-sm bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.age_18_to_35 || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">
                Voters 18 to 35 Age
              </CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/voter-list?max=150&min=36">
          <Card className="bg-dark  shadow-sm bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.age_36_to_150 || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">
                Voters Above 35 Age
              </CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/voter-list?d=dob">
          <Card className="bg-danger shadow-sm  bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.dob || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">
                Date of Birth Updated
              </CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/pdf-generator?type=booth">
          <Card className="bg-warning  shadow-sm bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.ps_building || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Total Booths</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/counting-tehsil">
          <Card className="bg-info  shadow-sm bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.tehsil_nm_v || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Total Colonies</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/pdf-generator?type=cast">
          <Card className="bg-success  shadow-sm bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.cst || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Total Cast</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/pdf-generator?type=ward">
          <Card className="bg-primary  shadow-sm bg-gradient ">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.wardno || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Total Ward</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link
          to={`/voter-list?todaybirth=${todaydate.getDate()}/${
            todaydate.getMonth() + 1
          }`}
        >
          <Card className="bg-primary  shadow-sm bg-gradient ">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.todayBirthday || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Today BirthDay</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link
          to={`/voter-list?todayanniv=${todaydate.getDate()}/${
            todaydate.getMonth() + 1
          }`}
        >
          <Card className="bg-success  shadow-sm bg-gradient ">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.todayAnniversary || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Today Anniversary</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
    </>
  )
}

export default Summery
