import React, { useState } from "react"

import { __formatDate } from "constants/constantfun"
import { __HOST } from "constants/apis"
import { Modal, ModalHeader } from "reactstrap"
import AddVoters from "pages/Search/comp/AddVoters"

const ListCom = ({ data, index }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [modal2, setModal2] = useState(false)
  const toggle2 = () => setModal2(!modal2)
  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}> Update Voter's Data</ModalHeader>
        <div className="p-3">
          <AddVoters voterid={data?.voter_id} />
        </div>
      </Modal>
      <Modal isOpen={modal2} toggle={toggle2} size="xl">
        <ModalHeader toggle={toggle2}>Voter's Details</ModalHeader>
        <div className="p-3">
          <AddVoters voterid={data?.voter_id} viewonly />
        </div>
      </Modal>

      <tr>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {index + 1}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.name_eng}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.surname}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.voter_id}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.family_eng}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.age}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.cast}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.tehsil_eng}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.mobile}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.dob}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <a
                className="btn btn-sm btn-soft-primary"
                id="viewtooltip-10"
                href="#"
                onClick={toggle2}
              >
                <i className="mdi mdi-eye-outline"></i>
              </a>
            </li>
            <li>
              <a
                className="btn btn-sm btn-soft-info"
                id="edittooltip-10"
                href="#"
                onClick={() => setModal(true)}
              >
                <i className="mdi mdi-pencil-outline"></i>
              </a>
            </li>
            {localStorage.getItem("login") == "superadmin" && (
              <li>
                <a
                  className="btn btn-sm btn-soft-danger"
                  id="deletetooltip-10"
                  href="#"
                >
                  <i className="mdi mdi-delete-outline"></i>
                </a>
              </li>
            )}
          </ul>
        </td>
      </tr>
    </React.Fragment>
  )
}

export default ListCom
