import {
  __makeAssignPlanPostRequest,
  __makeGetPlanGetRequest,
} from "constants/apis"
import React, { useEffect, useState } from "react"
import { Button, CardTitle, Col, Input, Row } from "reactstrap"

const AssignPlan = ({ userId }) => {
  const [state, setState] = useState({
    sms_token: "",
    call_token: "",
    whatsapp_token: "",
    loading: true,
    message: "",
  })
  const { sms_token, call_token, whatsapp_token, loading, message } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleAssignPlan = () => {
    __makeAssignPlanPostRequest(state, userId)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({ message: res.response.response_message })
        } else {
          updateState({ message: res.response.response_message })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const __handleGetData = () => {
    __makeGetPlanGetRequest(userId)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({
            sms_token: res.data.sms_token,
            call_token: res.data.call_token,
            whatsapp_token: res.data.whatsapp_token,
            loading: false,
          })
        } else if (res.response.response_code === "400") {
          updateState({ loading: false })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  useEffect(() => {
    __handleGetData()
    return () => {}
  }, [])

  return (
    <>
      {loading ? (
        <CardTitle className="text-center">
          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
          Loading Plan...
        </CardTitle>
      ) : (
        <>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={sms_token}
                  onChange={e => {
                    updateState({ sms_token: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">SMS Credits</label>
              </div>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={call_token}
                  onChange={e => {
                    updateState({ call_token: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Call Credits</label>
              </div>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={whatsapp_token}
                  onChange={e => {
                    updateState({ whatsapp_token: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">
                  Whatsapp Message Credits
                </label>
              </div>
            </Col>
          </Row>
          {message && (
            <div>
              <span className="text-success">{message}</span>
            </div>
          )}
          <div>
            <Button
              className="btn btn-bg float-end"
              onClick={__handleAssignPlan}
            >
              Give & Update
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default AssignPlan
