import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormFeedback,
  Input,
  Row,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  __getApiData,
  __makeGetCallDashboardGetRequest,
  __makeGetGalleryGetRequest,
  __postApiData,
} from "constants/apis"

const WhatsApp = props => {
  const [state, setState] = useState({
    template_id: "",
    send_to: "",
    more_numbers: "",
    error_message: "",
    success_message: "",
    loading: false,
    template: [],
    variables: "",
    template_name: "",
  })
  const {
    template_id,
    send_to,
    more_numbers,
    error_message,
    success_message,
    loading,
    template,
    template_message,
    variables,
    template_name,
  } = state

  // to update function
  const updateState = data => setState(state => ({ ...state, ...data }))

  const [dashboard, setdashboard] = useState({
    total: 0,
    M: 0,
    F: 0,
    new_voters: 0,
    age_18_to_35: 0,
    age_36_to_150: 0,
    dob: 0,
    mobile: 0,
    ps_building: 0,
    tehsil_nm_v: 0,
    cst: 0,
    wardno: 0,
    todayAniversary: 0,
    todayBirthday: 0,
  })

  const __handleGetDashboardGetRequest = () => {
    __makeGetCallDashboardGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setdashboard(res?.data)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const __handleSubmit = () => {
    __postApiData("/api/whatsapp/schedule_whatsapp", {
      template_id,
      send_to,
      variables,
      more_numbers: more_numbers
        ? more_numbers.split(",").map(num => num.trim())
        : [],
      vidhansabha: localStorage.getItem("type"),
      template_name: template_name,
    })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          return updateState({ success_message: res.response.response_message })
        }
        return updateState({ error_message: res.response.response_message })
      })
      .catch(error => {
        console.error(error)
        return updateState({ error_message: "Failed" })
      })
  }

  const __GetTemplateSubmit = () => {
    __getApiData("/api/whatsapp/tempate")
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({ template: res.data })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __handleGetDashboardGetRequest()
    __GetTemplateSubmit()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("WhatsApp ")}
            breadcrumbItem={props.t("Schedule Messages")}
          />

          <Card>
            <CardBody>
              <Row>
                <Col sm={4} md={4} lg={3}>
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      onChange={e => {
                        updateState({
                          template_id: e.target.value,
                          template_message:
                            template.find(
                              item => item.template_id === e.target.value
                            )?.template_message || "",
                          template_name:
                            template.find(
                              item => item.template_id === e.target.value
                            )?.template_name || "",
                        })
                      }}
                    >
                      <option value={""}>Choose ...</option>
                      {template.map((item, i) => (
                        <option key={i} value={item.template_id}>
                          {item.template_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="floatingSelectGrid">Template Name</label>
                  </div>
                </Col>
              </Row>

              <Row className="">
                <Col>
                  <div className="form-floating mb-3">
                    <Input
                      type="textarea"
                      className="form-control"
                      id="floatingnameInput"
                      placeholder="Enter..."
                      name="name"
                      value={template_message}
                      style={{ minHeight: 200 }}
                      disabled
                    />
                    <label htmlFor="floatingnameInput">Tempate</label>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <div className="form-floating mb-3">
                    <Input
                      type="text"
                      className="form-control"
                      id="floatingnameInput"
                      placeholder="Enter..."
                      name="name"
                      value={variables}
                      onChange={e => {
                        updateState({ variables: e.target.value })
                      }}
                    />

                    <label htmlFor="floatingnameInput">
                      Variable Value (" , "separate)
                    </label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col sm={6} md={3}>
                  <div className="form-floating mb-3">
                    <select
                      defaultValue="0"
                      className="form-select"
                      onChange={e => {
                        updateState({ send_to: e.target.value })
                      }}
                      value={send_to}
                    >
                      <option value="">Choose Send to</option>
                      <option value="all">All - {dashboard.total}</option>
                      <option value="new_voters">
                        New Voters - {dashboard.new_voters}
                      </option>
                      <option value="male_voters">
                        Male Voters - {dashboard.M}
                      </option>
                      <option value="female_voters">
                        Female Voters - {dashboard.F}
                      </option>
                      <option value="age_18_35">
                        Voters Age 18 To 35 - {dashboard.age_18_to_35}
                      </option>
                      <option value="above_age_35">
                        Voters Above 35 Age - {dashboard.age_36_to_150}
                      </option>
                      <option value="birthday">
                        Today Birth Day - {dashboard.todayBirthday}
                      </option>
                      <option value="anniversary">
                        Today Anniversary - {dashboard.todayAniversary}
                      </option>
                    </select>
                    <label htmlFor="floatingSelectGrid">Send to</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <Input
                    type="textarea"
                    id="textarea"
                    onChange={e => {
                      updateState({ more_numbers: e.target.value })
                    }}
                    value={more_numbers}
                    rows="3"
                    style={{
                      height: "250px",
                    }}
                    placeholder={`Note: Add more  mobile number.\nExample :\n\n9898000001,\n9099000002,\n9898000003,...`}
                    invalid={error_message ? true : false}
                    valid={success_message ? true : false}
                  />
                  <FormFeedback type="invalid">{error_message}</FormFeedback>
                  <FormFeedback>{success_message}</FormFeedback>
                </Col>
              </Row>

              <div className="col-md-12 col-sm-12">
                <Button
                  className="btn btn-bg my-4 float-end"
                  onClick={__handleSubmit}
                >
                  Schedule Message
                </Button>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(WhatsApp)
