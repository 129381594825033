import React, { useEffect, useState } from "react"
import { Button, Col, Input, Row } from "reactstrap"

const EditUser = ({ user, __handleUpdateUser, toggle2 }) => {
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    vidhanshabha: "",
    address: "",
  })

  const { name, email, password, vidhanshabha, address } = state

  const updateWork = data => setState(prv => ({ ...prv, ...data }))

  const __handleOnSubmit = async () => {
    let res = await __handleUpdateUser(user._id, state)
    console.log(res)

    if (res == "done") toggle2()
  }

  useEffect(() => {
    updateWork({ ...user })
  }, [])

  return (
    <>
      <Row>
        <Col sm={6} md={6} lg={6}>
          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={name}
              onChange={e => {
                updateWork({ name: e.target.value })
              }}
            />
            <label htmlFor="floatingnameInput">Name</label>
          </div>
        </Col>
        <Col sm={6} md={6} lg={6}>
          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={email}
              onChange={e => {
                updateWork({ email: e.target.value })
              }}
            />
            <label htmlFor="floatingnameInput">Email</label>
          </div>
        </Col>
        <Col sm={6} md={6} lg={6}>
          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={password}
              onChange={e => {
                updateWork({ password: e.target.value })
              }}
            />
            <label htmlFor="floatingnameInput">Password</label>
          </div>
        </Col>

        <Col sm={6} md={6} lg={6}>
          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={vidhanshabha}
              disabled
            />
            <label htmlFor="floatingnameInput">Vidhanshabha</label>
          </div>
        </Col>
        <Col sm={12} md={12} lg={12}>
          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={address}
              onChange={e => {
                updateWork({ address: e.target.value })
              }}
            />
            <label htmlFor="floatingnameInput">Address</label>
          </div>
        </Col>
      </Row>

      <Row>
        <div>
          <Button
            onClick={() => __handleOnSubmit()}
            className="btn btn-success float-end"
          >
            Save
          </Button>
        </div>
      </Row>
    </>
  )
}

export default EditUser
