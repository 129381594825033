import axios from "axios"
import React, { useRef, useState } from "react"
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import ReactAudioPlayer from "react-audio-player"
//Lightbox
import "react-image-lightbox/style.css"
import { __HOST } from "constants/apis"
import audioImage from "../../../assets/images/audio_img.png"

const GalleryList = ({ cardData, __handleDeleteNotes }) => {
  const [modal1, setModal1] = React.useState(false)
  const toggle1 = () => {
    setModal1(!modal1)
  }

  const [isPlaying, setIsPlaying] = useState(false)

  return (
    <React.Fragment>
      <Modal
        isOpen={modal1}
        fade={false}
        toggle={toggle1}
        className="top-50 left-50 mx-auto"
        style={{ transform: "translateY(-50%)" }}
      >
        <ModalBody>
          <Card className="btn-bg">
            <CardBody
              style={{ fontWeight: "600" }}
              className="text-center font-size-14"
            >
              Delete Audio
            </CardBody>
          </Card>
          <p className="text-center">
            Are you sure you want to delete this Audio!
          </p>
          <div className="d-flex gap-2 mt-3 justify-content-center">
            <Button
              onClick={toggle1}
              className="btn blue-bg  mx-2 bg-gradient py-1"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={async () => {
                setModal1(false)
                __handleDeleteNotes(cardData._id)
              }}
              className="btn btn-danger mx-2 bg-gradient py-1"
            >
              Submit
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Col lg={3} md={4} sm={5}>
        <Card className="btn-bg">
          <Row className="no-gutters align-items-center m-2 justify-content-center  h-100">
            <Col
              sm={12}
              className=" rounded p-1 d-flex justify-content-center align-items-center  rounded-0 mb-2 "
              style={{ height: "130px" }}
            >
              <CardImg
                className="img-fluid h-100"
                src={audioImage}
                alt="Skote"
                style={{ width: "auto" }}
              />
            </Col>

            <ReactAudioPlayer
              src={
                "https://digitalchunavparchar.com/uploads/" +
                cardData.audio_path
              }
              autoPlay={isPlaying}
              controls
              onEnded={() => setIsPlaying(false)}
              listenInterval={100}
              className=""
            />
            <div className="d-flex align-items-center mt-2 mx-0 px-0  justify-content-center border-top py-2">
              <p className="my-auto">Audio File Name : </p>
              <CardTitle className=" my-auto mx-2">
                {cardData?.audio_name}
              </CardTitle>
            </div>

            <span
              className=" d-flex align-items-center justify-content-end gap-2"
              style={{
                width: "100px",
                height: "40px",
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
            >
              <span
                onMouseEnter={e =>
                  (e.target.className = "bx bx-trash  font-size-22 text-danger")
                }
                onMouseLeave={e =>
                  (e.target.className = "bx bx-trash  font-size-22 text-white")
                }
                onClick={toggle1}
                className="bx bx-trash  font-size-22 text-white "
                style={{ cursor: "pointer" }}
              />
            </span>
          </Row>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default GalleryList
