import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import Search from "pages/Search"
import AllDataListing from "pages/AllDataListing"
import ColorVoterList from "pages/ColorVoterList"
import BlackAndWhiteVoterList from "pages/BlackAndWhiteVoterList"
import Alphabetical from "pages/Alphabetical"
import Counting from "pages/Counting"
import CountingAreabies from "pages/CountingAreabies"
import PdfGenrator from "pages/PdfGenrator"
import CreateWork from "pages/CreateWork"
import DevelopmentWork from "pages/DevelopmentWork"
import FirstTimeVoting from "pages/FirstTimeVoting"
import LeaderManagement from "pages/LeaderManagement"
import TeamManagement from "pages/TeamManagement"
import LoginAdmin from "pages/Authentication/LoginAdmin"
import PoleAdmin from "pages/Pole"
import PoleUser from "pages/Authentication/Pole"
import SMStemplate from "pages/SMStemplate"
import SmsList from "pages/SmsList"
import UploadAudio from "pages/UploadAudio"
import Call from "pages/Call"
import Term_Con from "pages/Term_Con"
import Policy from "pages/Policy"
import ExcelImport from "pages/ExcelImport"
import Whatsapp from "pages/whatsapp"
import WhatsAppHistory from "pages/whatsapp/WhatsAppHistory"
import CallHistory from "pages/Call/CallHistory"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/voter-list", component: <Search /> },
  { path: "/reports", component: <AllDataListing /> },
  { path: "/color-report", component: <ColorVoterList /> },
  { path: "/black-white-report", component: <BlackAndWhiteVoterList /> },
  { path: "/alphabetical-order-report", component: <Alphabetical /> },
  { path: "/counting", component: <Counting /> },
  { path: "/counting-tehsil", component: <CountingAreabies /> },
  { path: "/pdf-generator", component: <PdfGenrator /> },
  { path: "/work", component: <CreateWork /> },
  { path: "/development-work", component: <DevelopmentWork /> },
  { path: "/first-time-voting", component: <FirstTimeVoting /> },
  { path: "/leader-management", component: <LeaderManagement /> },
  { path: "/team-management", component: <TeamManagement /> },
  { path: "/polls", component: <PoleAdmin /> },
  { path: "/sms-template", component: <SMStemplate /> },
  { path: "/sms-list", component: <SmsList /> },
  { path: "/audio-list", component: <UploadAudio /> },
  { path: "/call", component: <Call /> },
  { path: "/t&s", component: <Term_Con /> },
  { path: "/privacy_policy", component: <Policy /> },
  { path: "/excel_import", component: <ExcelImport /> },
  { path: "/schedule-whatsapp", component: <Whatsapp /> },
  { path: "/whatsapp-history", component: <WhatsAppHistory /> },
  { path: "/call-history", component: <CallHistory /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/admin/login", component: <LoginAdmin /> },
  { path: "/poll-user/:id", component: <PoleUser /> },
  // { path: "/register", component: <Register /> },
  // { path: "/forgot-password", component: <ForgetPassword /> },
  // { path: "/email-verification", component: <TwostepVerification /> },
  // { path: "/reset-password", component: <ResetPassword /> },
]

export { authProtectedRoutes, publicRoutes }
