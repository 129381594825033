import React from "react"

import { __formatDate } from "constants/constantfun"
import { __HOST } from "constants/apis"
import { Link } from "react-router-dom"

const ListCom = ({ data, index }) => {
  console.log(data)
  return (
    <React.Fragment>
      <tr className="bg-danger">
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {index + 1}
        </td>
        <td className={` ${index % 2 == 1 && "bg-light"} `}>{data._id}</td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {(data["M"] ? data["M"] : 0) + (data["F"] ? data["F"] : 0)}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data["M"] ? data["M"] : 0}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data["F"] ? data["F"] : 0}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          <Link to={`/voter-list?section_nm_v=${data._id}`} target="_blank">
            <i className="mdi mdi-eye"></i>
          </Link>
        </td>
      </tr>
    </React.Fragment>
  )
}

export default ListCom
