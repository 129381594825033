import {
  __makeGetVoterDetailsGetRequest,
  __makeUpdayeVoterDetailsPostRequest,
} from "constants/apis"
import React, { useEffect, useState } from "react"
import { Button, Col, Input, Row } from "reactstrap"

const AddVoters = ({ voterid, viewonly }) => {
  const [showText, setshowText] = useState(null)
  const [state, setState] = useState({
    status: false,
    vidhansabha: "",
    ward_name: "",
    part_no: "",
    ac_no: "",
    sr_no: "",
    name_hin: "",
    name_eng: "",
    surname: "",
    email_id: "",
    family_hin: "",
    family_eng: "",
    relation_type: "",
    sex: "",
    age: "",
    voter_id: voterid || "",
    house_no: "",
    cast: "",
    mobile: "",
    voter_status: "",
    anubhag: "",
    pollingbooth: "",
    dob: "",
    aniversary_date: "",
    is_voted: "",
    tehsil_hin: "",
    tehsil_eng: "",
    address: "",
    block: "",
  })

  const {
    vidhansabha,
    ward_name,
    part_no,
    ac_no,
    sr_no,
    name_hin,
    name_eng,
    surname,
    email_id,
    family_hin,
    family_eng,
    relation_type,
    sex,
    age,
    voter_id,
    house_no,
    cast,
    mobile,
    voter_status,
    anubhag,
    pollingbooth,
    dob,
    aniversary_date,
    is_voted,
    tehsil_hin,
    tehsil_eng,
    address,
    block,
  } = state

  const UpdateState = data => setState(prv => ({ ...prv, ...data }))

  const __handleGetVotesData = () => {
    __makeGetVoterDetailsGetRequest(voter_id)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          UpdateState({ ...res.data, status: true })
          setshowText("Update Voter's Details")
        } else {
          window.alert(" no user found ")
          setshowText("Add Voter's Details")
        }
      })
      .catch(error => {
        console.error(error)
        setshowText("Add Voter's Details")
      })
  }
  const __handleUpdateVotesData = () => {
    __makeUpdayeVoterDetailsPostRequest(state)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          window.alert("Update Successfully")
        } else {
          window.alert("Failed ")
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    voterid && __handleGetVotesData()
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col sm={6} md={6} lg={3}>
          <div className="form-floating  mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={voter_id}
              onChange={e => {
                UpdateState({ voter_id: e.target.value })
              }}
            />
            <label htmlFor="floatingnameInput">Voter Id</label>
          </div>
        </Col>
        {!viewonly && (
          <Col className="">
            <Button
              className="btn btn-bg bg-gradient  mt-2"
              onClick={__handleGetVotesData}
            >
              Search Voter
            </Button>
          </Col>
        )}
      </Row>
      {showText && (
        <>
          <div className=" border-2 border-bottom mb-3"></div>
          {!viewonly && <h5 className="mb-2">{showText}</h5>}
          <Row>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={vidhansabha}
                  onChange={e => {
                    UpdateState({ vidhansabha: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Vidhansabha</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={ward_name}
                  onChange={e => {
                    UpdateState({ ward_name: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Ward Name</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={part_no}
                  onChange={e => {
                    UpdateState({ part_no: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Part Number</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={sr_no}
                  onChange={e => {
                    UpdateState({ sr_no: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Sr.No</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={name_hin}
                  onChange={e => {
                    UpdateState({ name_hin: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Name(HI)</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={name_eng}
                  onChange={e => {
                    UpdateState({ name_eng: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Name(EN)</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={surname}
                  onChange={e => {
                    UpdateState({ surname: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Surname</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={email_id}
                  onChange={e => {
                    UpdateState({ email_id: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Email ID</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={family_hin}
                  onChange={e => {
                    UpdateState({ family_hin: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Family Name(HI)</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={family_eng}
                  onChange={e => {
                    UpdateState({ family_eng: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Family Name(EN)</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={relation_type}
                  onChange={e => {
                    UpdateState({ relation_type: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Relation Type</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={sex}
                  onChange={e => {
                    UpdateState({ sex: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Gender(M,F)</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={age}
                  onChange={e => {
                    UpdateState({ age: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Age</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={house_no}
                  onChange={e => {
                    UpdateState({ house_no: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">House Number</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={cast}
                  onChange={e => {
                    UpdateState({ cast: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Cast</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={mobile}
                  onChange={e => {
                    UpdateState({ mobile: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Mobile</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={voter_status}
                  onChange={e => {
                    UpdateState({ voter_status: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Voter Status</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={anubhag}
                  onChange={e => {
                    UpdateState({ anubhag: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Anubhag</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={pollingbooth}
                  onChange={e => {
                    UpdateState({ pollingbooth: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Polling Booth</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={dob}
                  onChange={e => {
                    UpdateState({ dob: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">DOB(DD/MM/YYYY)</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={aniversary_date}
                  onChange={e => {
                    UpdateState({ aniversary_date: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">
                  Anniversary Date(DD/MM/YYYY)
                </label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={is_voted}
                  onChange={e => {
                    UpdateState({ is_voted: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Is Voted(true,false)</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={tehsil_hin}
                  onChange={e => {
                    UpdateState({ tehsil_hin: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Tehsil(HI)</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={tehsil_eng}
                  onChange={e => {
                    UpdateState({ tehsil_eng: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Tehsil(EN)</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={address}
                  onChange={e => {
                    UpdateState({ address: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Address</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={block}
                  onChange={e => {
                    UpdateState({ block: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Block</label>
              </div>
            </Col>
          </Row>
          <div className=" border-2 border-bottom mb-3"></div>
          {!viewonly && (
            <div className="">
              <Button
                onClick={__handleUpdateVotesData}
                className="btn btn-bg bg-gradient float-end"
              >
                Save
              </Button>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  )
}

export default AddVoters
