import axios from "axios"
import { __apiFormHeader, __apiHeader, __authToken } from "./constantfun"

const __LIVE = "https://digitalchunavparchar.com:5001"
const __LOCAL = "http://localhost:5001"

export const __HOST = () => {
  if (process.env.NODE_ENV === "development") {
    return __LOCAL
  } else {
    return __LIVE
  }
}

export const __getApiData = async endpoint => {
  const headers = __apiHeader(__authToken())

  try {
    const response = await axios.get(`${__HOST()}${endpoint}`, {
      headers,
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const __postApiData = (endpoint, data) => {
  const headers = __apiHeader(__authToken())

  return axios
    .post(`${__HOST()}${endpoint}`, data, {
      headers,
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export const __putApiData = async (endpoint, data) => {
  const headers = __apiHeader(__authToken())
  return axios
    .put(`${__HOST()}${endpoint}`, data, {
      headers,
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export const __deleteApiData = async endpoint => {
  const headers = __apiHeader(__authToken())
  return axios
    .delete(`${__HOST()}${endpoint}`, {
      headers,
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeSignupPostRequest(apidata) {
  const url = __HOST() + "/api/auth/createuser"
  const headers = {
    "Content-Type": "multipart/form-data",
    "auth-token": __authToken(),
  }
  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeCreatePostRequest(apidata) {
  const url = __HOST() + "/api/mobile/createuser"
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makegetteamUsersGetRequest() {
  const url = __HOST() + "/api/mobile/getusers"
  const headers = __apiHeader(__authToken())

  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makegetUsersGetRequest() {
  const url = __HOST() + "/api/auth/getusers"
  const headers = __apiHeader(__authToken())

  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeEmailVerificationPostRequest(apidata) {
  const url = __HOST() + "/api/auth/verification"
  const headers = __apiHeader(apidata?.authToken)

  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeResendVerficationCodePostRequest(apidata) {
  const url = __HOST() + "/api/auth/resend_otp"
  const headers = __apiHeader(apidata?.authToken)

  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeLoginPostRequest(apidata) {
  const url = __HOST() + "/api/auth/login"
  const headers = __apiHeader(apidata?.authToken)
  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeGetListReportPostRequest(apidata) {
  const url = __HOST() + "/api/party/voter_list/filter/report"
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetListFirstTimeGetRequest(limit, skip) {
  const url =
    __HOST() +
    `/api/party/voter_list/firsttime/${limit}/${skip}/` +
    localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetListPostRequest(apidata) {
  const url = __HOST() + "/api/party/voter_list/filter"
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetListSortPostRequest(apidata) {
  const url = __HOST() + "/api/party/voter_list/filter/sort"
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetListCountSortPostRequest() {
  const url = __HOST() + "/api/party/count-sex/" + localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetListCountSortTehsilPostRequest() {
  const url =
    __HOST() + "/api/party/count-sex-tehsil/" + localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetTehsilListGetRequest() {
  const url = __HOST() + "/api/party/tehsil/" + localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetBlockListGetRequest() {
  const url = __HOST() + "/api/party/block"
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetPartNoListGetRequest() {
  const url = __HOST() + "/api/party/partno/" + localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetWardNoListGetRequest() {
  const url = __HOST() + "/api/party/ward/" + localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetCastNoListGetRequest() {
  const url = __HOST() + "/api/party/cast/" + localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetVidhansabhaListGetRequest() {
  const url = __HOST() + "/api/party/vidhansabha"
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetPartyListGetRequest() {
  const url = __HOST() + "/api/party/party_list"
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeStartSendMessagePostRequest(apidata, type) {
  const url = __HOST() + "/api/message/openChrome/" + type
  const headers = __apiHeader(__authToken())

  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeUploadGalleryFilePostRequest(apidata) {
  const url = __HOST() + "/api/gallery/upload_file_gallery"
  const headers = __apiFormHeader(__authToken())

  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeGetGalleryGetRequest() {
  const url = __HOST() + "/api/gallery/get_gallery"
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeDeleteGalleryDeleteRequest(_id) {
  const url = __HOST() + "/api/gallery/delete_file/" + _id
  const headers = __apiHeader(__authToken())

  return axios
    .delete(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeGetCompanionsGetRequest() {
  const url = __HOST() + "/api/companion/get_companions"
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeCreateCompanionsPostRequest(apidata) {
  const url = __HOST() + "/api/companion/create_companion"
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeGetVoterListGetRequest(skip, limit) {
  const url =
    __HOST() +
    `/api/party/voter_list/${skip}/${limit}/` +
    localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetFemaleVoterListGetRequest(skip, limit, sex) {
  const url =
    __HOST() +
    `/api/party/${sex}/voter_list/${skip}/${limit}/` +
    localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetVoterListAlphaGetRequest(skip, limit) {
  const url =
    __HOST() +
    `/api/party/voter_list/filter/sort/${skip}/${limit}/` +
    localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetCastListGetRequest(skip, limit) {
  const url =
    __HOST() + `/api/party/cast-sex-count/` + localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetWardListGetRequest(skip, limit) {
  const url = __HOST() + `/api/party/wardlist/` + localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetPDFBlockListGetRequest(skip, limit) {
  const url =
    __HOST() + `/api/party/pollingbooth/` + localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeCreateWorkPostRequest(apiData) {
  const url = __HOST() + `/api/party/create_work`
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, apiData, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetWorkPostRequest(filter) {
  const url = __HOST() + `/api/party/get_work`
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, filter, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeCreateDevelopmentWorkPostRequest(apiData) {
  const url = __HOST() + `/api/party/create_development_work`
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, apiData, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetDevelopmentWorkPostRequest(filter) {
  const url = __HOST() + `/api/party/get_development_work`
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, filter, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetDashboardGetRequest() {
  const url = __HOST() + "/api/mobile/dashboard/" + localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetCallDashboardGetRequest() {
  const url =
    __HOST() + "/api/mobile/call_dashboard/" + localStorage.getItem("type")
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetVoterDetailsGetRequest(id) {
  const url = __HOST() + "/api/mobile/getVoterdata"
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, { id }, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeUpdayeVoterDetailsPostRequest(apiData) {
  const url = __HOST() + "/api/mobile/updateVoterData"
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, apiData, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeUploadImagePostRequest(apiData) {
  const url = __HOST() + "/api/pole/upload_imgae"
  const headers = {
    "Content-Type": "multipart/form-data",
    "auth-token": __authToken(),
  }
  return axios
    .post(url, apiData, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeCreatePolePostRequest(apiData) {
  const url = __HOST() + "/api/pole/create_pole"
  const headers = __apiHeader(__authToken())

  return axios
    .post(url, apiData, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetPoleListPostRequest(filter) {
  const url = __HOST() + `/api/pole/get_pole`
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, filter, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeGetPoleGetRequest(pole_id) {
  const url = __HOST() + `/api/pole/get_pole_data/${pole_id}`
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeUpdatePolePutRequest(pole_id, option_id) {
  const url = __HOST() + `/api/pole/updateCounts/${pole_id}/${option_id}`
  const headers = __apiHeader(__authToken())
  return axios
    .put(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeUpdatePoleStatusPutRequest(pole_id, apidata) {
  const url = __HOST() + `/api/pole/updatepole/${pole_id}`
  const headers = __apiHeader(__authToken())
  return axios
    .put(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeDeletePoleStatusDeleteRequest(pole_id) {
  const url = __HOST() + `/api/pole/deletepole/${pole_id}`
  const headers = __apiHeader(__authToken())
  return axios
    .delete(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeUpdateMobileUserStatusPutRequest(id, apidata) {
  const url = __HOST() + `/api/mobile/updatemobileuserstatus/${id}`
  const headers = __apiHeader(__authToken())
  return axios
    .put(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeUpdateAdminUserStatusPutRequest(id, apidata) {
  const url = __HOST() + `/api/auth/updateadminuserstatus/${id}`
  const headers = __apiHeader(__authToken())
  return axios
    .put(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeDeleteAdminRequest(id) {
  const url = __HOST() + `/api/auth/deleteadmin/${id}`
  const headers = __apiHeader(__authToken())
  return axios
    .delete(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeDeleteUserRequest(id) {
  const url = __HOST() + `/api/mobile/deletemobileuser/${id}`
  const headers = __apiHeader(__authToken())
  return axios
    .delete(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeAssignSmsTemplatePostRequest(apidata) {
  const url = __HOST() + `/api/sms/assign_template`
  const headers = __apiHeader(__authToken())

  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makegetSMSGetRequest(skip, limit) {
  const url = __HOST() + `/api/sms/get_smstemplate/${skip}/${limit}`
  const headers = __apiHeader(__authToken())

  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeUpdateSMSStatusPutRequest(id, apidata) {
  const url = __HOST() + `/api/sms/change_status_smstemplate/${id}`
  const headers = __apiHeader(__authToken())
  return axios
    .put(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeDeleteSMSDeleteRequest(id) {
  const url = __HOST() + `/api/sms/delete_smstemplate/${id}`
  const headers = __apiHeader(__authToken())
  return axios
    .delete(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeIvrCallPostRequest(apidata) {
  const url = __HOST() + "/api/call/ivr_call"
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeGetPlanGetRequest(id) {
  const url = __HOST() + "/api/auth/check_plan/" + id
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeAssignPlanPostRequest(apidata, id) {
  const url = __HOST() + "/api/auth/give_credits/" + id
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeUserPlanGetRequest() {
  const url = __HOST() + "/api/auth/getuser_plan"
  const headers = __apiHeader(__authToken())
  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makegetTermAndPolicyGetRequest(path) {
  const url = __HOST() + "/api/termsPrivacy/" + path
  const headers = __apiHeader(__authToken())

  return axios
    .get(url, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export function __makeUpdatetermsPrivacyPostRequest(path, apidata) {
  const url = __HOST() + "/api/termsPrivacy/" + path
  const headers = __apiHeader(__authToken())

  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export function __makeImportVotersPostRequest(apidata) {
  const url = __HOST() + "/api/party/import_list"
  const headers = __apiHeader(__authToken())
  return axios
    .post(url, apidata, { headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })
}
