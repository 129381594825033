import React, { Fragment, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { withTranslation } from "react-i18next"
import { __formatDate, __generateRandomString } from "constants/constantfun"
import {
  __makeCreateWorkPostRequest,
  __makeDeleteAdminRequest,
  __makeGetVidhansabhaListGetRequest,
  __makeGetWorkPostRequest,
  __makeSignupPostRequest,
  __makeUpdateAdminUserStatusPutRequest,
  __makegetUsersGetRequest,
} from "constants/apis"
import ListData from "./comp/ListData"

const LeaderManagement = props => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    type: "admin",
    vidhanshabha: "",
    address: "",
    image_one: null,
    image_two: null,
  })

  const {
    name,
    email,
    password,
    type,
    vidhanshabha,
    address,
    image_one,
    image_two,
  } = state
  const updateWork = data => setState(prv => ({ ...prv, ...data }))

  const [list, setlist] = useState(null)

  const __handleWork = () => {
    const formdata = new FormData()
    formdata.append("name", name)
    formdata.append("email", email)
    formdata.append("password", password)
    formdata.append("type", type)
    formdata.append("vidhanshabha", vidhanshabha)
    formdata.append("address", address)
    formdata.append("address", address)
    formdata.append("image_one", image_one)
    formdata.append("image_two", image_two)

    __makeSignupPostRequest(state)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          toggle()
          setlist([res?.data, ...list])
        } else {
          window.alert(res.response.response_message)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const __handleGetWork = filter => {
    __makegetUsersGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
        } else {
          setlist([])
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __handleGetWork({})
  }, [])

  const [partList, setpartList] = useState()

  const __handleGetParty = () => {
    __makeGetVidhansabhaListGetRequest()
      .then(res => {
        if (res.response.response_code === "200") {
          setpartList(res?.data)
        } else {
          setpartList([])
        }
      })
      .catch(error => {
        console.error(error)
        setpartList([])
      })
  }

  const __handleUpdateStatus = (id, status) => {
    __makeUpdateAdminUserStatusPutRequest(id, { status })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleUpdateUser = (id, data) => {
    return __makeUpdateAdminUserStatusPutRequest(id, data)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
          return "done"
        }
        window.alert(res.response.response_message)
        return "fail"
      })
      .catch(error => {
        console.error(error)
        window.alert("Failed")
        return "fail"
      })
  }
  const __handleDeleteUser = (id, data) => {
    return __makeDeleteAdminRequest(id)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
          return "done"
        }
        window.alert(res.response.response_message)
        return "fail"
      })
      .catch(error => {
        console.error(error)
        window.alert("Failed")
        return "fail"
      })
  }

  useEffect(() => {
    __handleGetParty()
    localStorage.getItem("login") == "admin" && localStorage.clear()
  }, [])

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle} size="xl" centered>
        <ModalHeader toggle={toggle}>Add Leaders </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6} md={6} lg={3}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={name}
                  onChange={e => {
                    updateWork({ name: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Name</label>
              </div>
            </Col>
            <Col sm={6} md={6} lg={3}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={email}
                  onChange={e => {
                    updateWork({ email: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Email</label>
              </div>
            </Col>
            <Col sm={6} md={6} lg={3}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={password}
                  onChange={e => {
                    updateWork({ password: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Password</label>
              </div>
            </Col>

            <Col sm={6} md={6} lg={3}>
              <div className="form-floating mb-3">
                <select
                  defaultValue="0"
                  className="form-select"
                  value={vidhanshabha}
                  onChange={e => {
                    updateWork({ vidhanshabha: e.target.value })
                  }}
                >
                  <option value="">Choose ....</option>
                  {partList?.map((part, i) => (
                    <>
                      <option key={i} value={part}>
                        {part}
                      </option>
                    </>
                  ))}
                </select>
                <label htmlFor="floatingSelectGrid">Vidhanshabha</label>
              </div>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={address}
                  onChange={e => {
                    updateWork({ address: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Address</label>
              </div>
            </Col>
          </Row>
          <div className="border-bottom border-2 mb-3"></div>
          <Row>
            <Col sm={12} lg={4} className="mb-3">
              <label htmlFor="resume">Banner</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  updateWork({ image_one: e.target.files[0] })
                }}
              />
            </Col>
            <Col sm={12} lg={4} className="mb-3">
              <label htmlFor="resume">Party Logo</label>
              <input
                type="file"
                className="form-control"
                onChange={e => {
                  updateWork({ image_two: e.target.files[0] })
                }}
              />
            </Col>
          </Row>
          <Row>
            <div>
              <Button
                onClick={() => __handleWork()}
                className="btn btn-success float-end"
              >
                Save
              </Button>
            </div>
          </Row>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Leader Management")}
          />

          {/* <Filter __handleGetWork={__handleGetWork} /> */}
          <Card className="mt-2">
            <CardBody>
              <Row>
                <Col className="justify-content-end ">
                  <Button
                    onClick={toggle}
                    className="btn btn-bg bg-gradient float-end"
                  >
                    Add +
                  </Button>
                </Col>
              </Row>
              <Row>
                <Fragment>
                  <div className="table-responsive react-table">
                    <Table bordered hover className={"custom-header-css mt-3"}>
                      <thead className="table-light table-nowrap">
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Name</th>
                          <th className="text-center">Vidhanshabha</th>
                          <th className="text-center">Email</th>
                          <th className="text-center">Address</th>
                          <th className="text-center">Date</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Assign Plan</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {list?.map((data, i) => (
                          <ListData
                            data={data}
                            i={i}
                            key={data._id}
                            __handleUpdateStatus={__handleUpdateStatus}
                            __handleUpdateUser={__handleUpdateUser}
                            __handleDeleteUser={__handleDeleteUser}
                          />
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Fragment>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(LeaderManagement)
