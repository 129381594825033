import React, { Fragment, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __formatDate, __generateRandomString } from "constants/constantfun"
import {
  __makeCreatePostRequest,
  __makeCreateWorkPostRequest,
  __makeDeleteSMSDeleteRequest,
  __makeGetVidhansabhaListGetRequest,
  __makeGetWorkPostRequest,
  __makeSignupPostRequest,
  __makeUpdateMobileUserStatusPutRequest,
  __makeUpdateSMSStatusPutRequest,
  __makegetSMSGetRequest,
  __makegetUsersGetRequest,
  __makegetteamUsersGetRequest,
} from "constants/apis"
import ListTemp from "./comp/ListTemp"

const SmsList = props => {
  //meta title
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const [state, setState] = useState({
    name: "",
    phone: "",
    password: "",
  })

  const { name, phone, password } = state
  const updateWork = data => setState(prv => ({ ...prv, ...data }))

  const [list, setlist] = useState(null)

  const __handleWork = () => {
    __makeCreatePostRequest(state)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          toggle()
          setlist([res?.data, ...list])
        } else {
          window.alert(res.response.response_message)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const __handleGetWork = filter => {
    __makegetSMSGetRequest(0, 500)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
        } else {
          setlist([])
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleUpdateStatus = (id, status) => {
    __makeUpdateSMSStatusPutRequest(id, { is_active: status })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          __handleGetWork()
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleUpdatetemp = (id, data) => {
    return __makeUpdateSMSStatusPutRequest(id, { ...data })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          __handleGetWork()
          return "done"
        }
        window.alert("failed")
        return "failed"
      })
      .catch(error => {
        console.error(error)
        window.alert("failed")
        return "failed"
      })
  }
  const __handleDeletetemp = id => {
    return __makeDeleteSMSDeleteRequest(id)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          __handleGetWork()
          return "done"
        }
        window.alert("failed")
        return "failed"
      })
      .catch(error => {
        console.error(error)
        window.alert("failed")
        return "failed"
      })
  }

  useEffect(() => {
    __handleGetWork({})
    localStorage.getItem("login") == "admin" && localStorage.clear()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("SMS Template List")}
          />

          <Card>
            <CardBody>
              <Row>
                <Fragment>
                  <div className="table-responsive react-table">
                    <Table bordered hover className={"custom-header-css mt-3"}>
                      <thead className="table-light table-nowrap">
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Email</th>
                          <th className="text-center">Template Name</th>
                          <th className="text-center">Template ID</th>
                          <th className="text-center">Template Type</th>
                          <th className="text-center">Date</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {list?.map((data, i) => (
                          <ListTemp
                            data={data}
                            i={i}
                            __handleUpdateStatus={__handleUpdateStatus}
                            __handleUpdatetemp={__handleUpdatetemp}
                            __handleDeletetemp={__handleDeletetemp}
                            key={data._id}
                          />
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Fragment>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(SmsList)
