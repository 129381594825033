import React, { Fragment, useEffect, useState } from "react"
import { HashLoader } from "react-spinners"

import { Col, Row, Card, CardBody, Table, CardImg, Button } from "reactstrap"
import ListCom from "./ListCom"
import { __generateRandomString } from "constants/constantfun"
import noImage from "../../../assets/images/no_image.png"
import ReactToPrint from "react-to-print"

const List = ({ companionList, count }) => {
  const componentRef = React.useRef()

  return (
    <React.Fragment>
      {companionList && companionList?.length > 0 && (
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-success  mx-2 ">Download</button>
          )}
          content={() => componentRef.current}
        />
      )}
      <Row>
        <Col xs="12">
          {companionList ? (
            companionList.length > 0 ? (
              <>
                <div className=" justify-content-end d-flex h6">
                  Report: {count}
                </div>
                <Card>
                  <CardBody>
                    <Fragment>
                      <div
                        className="table-responsive react-table"
                        ref={componentRef}
                      >
                        <Table
                          bordered
                          hover
                          className={"custom-header-css mt-3"}
                        >
                          <thead className="table-light table-nowrap">
                            <tr>
                              <th className="text-center">#</th>
                              <th className="text-center">Name</th>
                              <th className="text-center">Surname</th>
                              <th className="text-center">Voter Id</th>
                              <th className="text-center">Family Name</th>
                              <th className="text-center">Age</th>
                              <th className="text-center">Cast</th>
                              <th className="text-center">Tehsil</th>
                              <th className="text-center">Mobile</th>
                              <th className="text-center">DOB</th>
                              <th className="text-center">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {companionList.map((data, i) => (
                              <ListCom
                                data={data}
                                index={i}
                                key={__generateRandomString(10)}
                              />
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Fragment>

                    {/* <Row>
                      <Col className="my-3 justify-content-end d-flex">
                        <Button
                          className="btn btn-primary mx-2 px-4"
                          onClick={() => {
                            console.log(offset > 0)
                            offset > 0 && updatePage({ offset: offset - limit })
                          }}
                        >
                          Prv
                        </Button>
                        <Button
                          className="btn btn-primary mx-2 px-4"
                          onClick={() => {
                            updatePage({ offset: offset + limit })
                          }}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row> */}
                  </CardBody>
                </Card>
              </>
            ) : (
              <>{NoDataFound()}</>
            )
          ) : (
            <>{Loader()}</>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )

  function NoDataFound() {
    return (
      <>
        <Row
          className=" d-flex justify-content-center  align-items-center "
          style={{ height: "60vh" }}
        >
          <CardImg
            className="img-fluid"
            src={noImage}
            alt=""
            style={{
              maxHeight: "300px",
              maxWidth: "100%",
              width: "auto",
            }}
          />
        </Row>
      </>
    )
  }
  function Loader() {
    return (
      <>
        <Row
          className=" d-flex justify-content-center  align-items-center "
          style={{ height: "60vh" }}
        >
          <HashLoader color="#36d7b7" />
        </Row>
      </>
    )
  }
}

export default List
