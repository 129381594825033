import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import Gallery from "./comp/Gallery"
import { __makeGetGalleryGetRequest } from "constants/apis"
import AddAudio from "./comp/AddAudio"
const AudioGallery = props => {
  const [galleryList, setgalleryList] = useState()

  const __handleGetGallery = () => {
    __makeGetGalleryGetRequest()
      .then(res => {
        if (res.response.response_code === "200") {
          setgalleryList(res?.data?.reverse())
        } else {
          setgalleryList([])
        }
      })
      .catch(error => {
        console.error(error)
        setgalleryList([])
      })
  }

  useEffect(() => {
    __handleGetGallery()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Gallery")}
            breadcrumbItem={props.t("Audio Gallery")}
          />
          <AddAudio galleryList={galleryList} setgalleryList={setgalleryList} />
          <Gallery galleryList={galleryList} setgalleryList={setgalleryList} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(AudioGallery)
